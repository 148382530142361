import styled from 'styled-components';

export const Container = styled.div`
  margin: 0 auto;
  max-width: 100vw;
  width: 400px;
  display: grid;
  align-items: center;
  justify-content: space-evenly;
  div.fullPrice,
  div.discount {
    text-align: center;
    color: var(--blue-main);
    width: 400px;
    span {
      font-size: 2rem;
    }
  }
  @media (min-width: 900px) {
    width: 900px;
    max-width: 100vw;
    display: flex;
  }
`;
