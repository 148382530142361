import { Container } from './styles';

export function ProfessorPanel() {
  return (
    <>
      <h1>Quem vai me ensinar?</h1>
      <Container id="prof">
        <div className="profWrapper">
          <div className="profImage">
            <img
              src="https://s3-sa-east-1.amazonaws.com/uploads-trilhante-sp/posts/unorte/IMG-20220411-WA0008.jpg?_t=1649788658"
              alt="Foto da professora"
            />
          </div>
        </div>
        <div className="profData">
          <h2>Gabriela Carolina dos Santos Pinto</h2>
          <p>Advogada, Mestranda em Ensino e Processos Formativos (UNESP)</p>
          <p>Pós-graduada em Direito Homoafetivo e de Gênero pela UNISANTA</p>
          <p>Coordenadora da Comissão de Diversidade Sexual e de Gênero da 22ª Subseção da OAB</p>
          <p>Coordenadora Municipal da Aliança Nacional LGBTI+</p>
        </div>
      </Container>
    </>
  );
}
