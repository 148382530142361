import { Container } from './styles';
import { SignUpButton } from '../SignUpButton';

export function PricesPanel() {
  return (
    <>
      <h1>Qual é o investimento?</h1>
      <Container id="prices">
        <div className="fullPrice">
          <img
            src="https://s3-sa-east-1.amazonaws.com/uploads-trilhante-sp/posts/unorte/money.png?_t=1649790409"
            alt="Ícone de preço"
          />
          <p>Valor total do curso</p>
          <span>R$550,00</span>
        </div>
        <div className="discount">
          <img
            src="https://s3-sa-east-1.amazonaws.com/uploads-trilhante-sp/posts/unorte/offer.png?_t=1649790411"
            alt="Ícone de Desconto"
          />
          <p>Valor com desconto de lançamento</p>
          <span>R$375,00</span>
        </div>
      </Container>
      <em>Desconto válido para os primeiros 20 inscritos na 1ª turma.</em>
      <SignUpButton />
    </>
  );
}
