import { Container } from './styles';
import { SignUpButton } from '../SignUpButton';

export function VideoPanel() {
  return (
    <Container id="intro">
      <h1>Direito à Diversidade Sexual e de Gênero</h1>
      <div className="videoWrapper">
        <iframe
          src="https://player.vimeo.com/video/696080758?h=aa5a450739&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
          frameBorder="0"
          allow="autoplay; fullscreen; picture-in-picture"
          allowFullScreen
          title="Apresentação do curso de extensão"
        ></iframe>
      </div>
      <script src="https://player.vimeo.com/api/player.js"></script>
      <SignUpButton />
    </Container>
  );
}
