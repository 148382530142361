import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper';
import { InfoCard } from '../InfoCard';

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { Container } from './styles';

export function CardSwiper() {
  return (
    <Container>
      <h1>O que tem no curso?</h1>
      <Swiper className="slides" modules={[Navigation]} navigation slidesPerView={3}>
        <SwiperSlide>
          <InfoCard
            iconName="Ícone de Direitos Civis"
            iconSource="https://s3-sa-east-1.amazonaws.com/uploads-trilhante-sp/posts/unorte/civil-right-movement.png?_t=1649781180"
            cardTitle="Direitos Humanos"
            description="Direitos Humanos e proteção constitucional do Direito à Diversidade"
          />
        </SwiperSlide>
        <SwiperSlide>
          <InfoCard
            iconName="Ícone de Contexto Histórico"
            iconSource="https://s3-sa-east-1.amazonaws.com/uploads-trilhante-sp/posts/unorte/history.png?_t=1649784828"
            cardTitle="Contexto Histórico"
            description="Contexto histórico mundial dos direitos da população LGBTQIA+"
          />
        </SwiperSlide>
        <SwiperSlide>
          <InfoCard
            iconName="Ícone de Diversidade"
            iconSource="https://s3-sa-east-1.amazonaws.com/uploads-trilhante-sp/posts/unorte/rainbow-flag.png?_t=1649785593"
            cardTitle="Diversidade"
            description="Conceitos e noções preliminares sobre Diversidade Sexual e de Gênero"
          />
        </SwiperSlide>
        <SwiperSlide>
          <InfoCard
            iconName="Ícone de Crimes"
            iconSource="https://s3-sa-east-1.amazonaws.com/uploads-trilhante-sp/posts/unorte/cyber-crime.png?_t=1649785242"
            cardTitle="Crimes Relacionados"
            description="Análise da Homofobia, Intolerância e Crimes de Ódio"
          />
        </SwiperSlide>
        <SwiperSlide>
          <InfoCard
            iconName="Ícone de Ativismo Judicial"
            iconSource="https://s3-sa-east-1.amazonaws.com/uploads-trilhante-sp/posts/unorte/auction.png?_t=1649785592"
            cardTitle="Ativismo Judicial"
            description="Ativismo judicial e panorama jurídico sobre Diversidade Sexual e de Gênero no Brasil"
          />
        </SwiperSlide>
      </Swiper>
    </Container>
  );
}
