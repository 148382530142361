import styled from 'styled-components';

export const Container = styled.div`
  margin: 0 auto;
  padding: 0.5rem 0;
  max-width: 100vw;
  width: 400px;
  display: grid;
  border: 3px dashed var(--blue-main);
  border-radius: 10px;
  justify-content: center;
  align-items: center;

  div.profWrapper {
    display: flex;
    margin: 0 auto;
    .profImage {
      text-align: center;
      padding: 1rem 0.5rem;
      img {
        max-width: 200px;
        border: 3px solid var(--blue-main);
        border-radius: 150px;
      }
    }
  }
  div.profData {
    display: flex;
    flex-direction: column;
    text-align: center;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;
    h2 {
      color: var(--blue-main);
      font-weight: bold;
      padding-bottom: 1rem;
      max-width: 350px;
    }
    p {
      color: var(--blue-main);
      text-align: center;
      width: 300px;
      margin-bottom: 1rem;
    }
  }
  @media (min-width: 900px) {
    margin: 0 auto;
    max-width: 100vw;
    width: 900px;
    display: flex;
    border: 3px dashed var(--blue-main);
    border-radius: 10px;
    div.profWrapper .profImage img {
      max-width: 300px;
    }
    div.profData p {
      width: 500px;
    }
  }
`;
