import { Container } from './styles';

export function SignUpButton() {
  return (
    <Container>
      <a
        type="button"
        target="_blank"
        href="https://unorte.edu.br/curso-unorte/aspectos-praticos-e-juridicos-do-direito-a-diversidade-sexual-e-de-genero" rel="noreferrer"
      >
        Inscreva-se!
      </a>
      <div id="about"></div>
    </Container>
  );
}
