import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: center;
  justify-content: center;
  padding: 1rem;
  max-width: 300px;
  height: 450px;
  text-align: center;
  border: 3px solid var(--blue-main);
  border-radius: 20px;
  img {
    border: 3px solid;
    border-color: var(--orange-main);
    border-radius: 65px;
  }
  h2 {
    color: var(--blue-main);
  }
`;
