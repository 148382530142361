import { Container } from './styles';

export function Footer() {
  return (
    <Container>
      <div className="contact">
        <h3>Contatos</h3>
        <ul>
          <li>
            <a href="https://www.instagram.com/unipos.unorte/" target="_blank" rel="noreferrer">
              <img
                src="https://s3-sa-east-1.amazonaws.com/uploads-trilhante-sp/posts/unorte/icons8-instagram-old.svg"
                alt="instagram icon"
              />
              @unipos.unorte
            </a>
          </li>
          <li>
            <img
              src="https://s3-sa-east-1.amazonaws.com/uploads-trilhante-sp/posts/unorte/icons8-phone.svg"
              alt="telephone icon"
            />
            (17) 3203-2500
          </li>
          <li>
            <a href="https://unorte.edu.br/" target="_blank" rel="noreferrer">
              <img
                src="https://s3-sa-east-1.amazonaws.com/uploads-trilhante-sp/posts/unorte/icons8-globe-50%20(1).png?_t=1649793801"
                alt="web icon"
              />
              Site oficial
            </a>
          </li>
        </ul>
      </div>
      <div className="partners">
        <h3>Parceria</h3>
        <a href="https://trilhante.com.br">
        <img
          src="https://s3-sa-east-1.amazonaws.com/uploads-trilhante-sp/posts/unorte/completo_bgescuro_cor_smarg.png?_t=1649792513"
          alt="Logo do Trilhante"
        />
        </a>
      </div>
    </Container>
  );
}
