import { createGlobalStyle } from 'styled-components';

export const GlobalStyle = createGlobalStyle`
    :root{
        /* Colors */
        --background: #F0F2F5;
        --blue-main: #032c60;
        --orange-main: #FB8F59;
        --orange-dark: #E88873;
    }

    * {
        margin: 0;
        padding: 0;
        box-sizing: border-box;
    }

    html{
        @media(max-width: 1080px){
            font-size: 93.75%;
        }
        @media(max-width: 720px){
            font-size: 87.5%;
        }
    }

    body{
        background: var(--background);
        -webkit-font-smoothing: antialiased;
    }

    body, input, textarea, button{
        font-family: 'Poppins', sans-serif;
        font-weight: 400;
    }
    h1{
        text-align: center;
        padding: 2rem 0;
        color: var(--blue-main);
    }
    em{
        margin: 1rem 0;
        display: block;
        text-align: center;
        color: var(--blue-main);
    }
`;
