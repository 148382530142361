import styled from 'styled-components';

export const Container = styled.nav`
  display: flex;
  justify-content: center;
  padding: 0.75rem 0;
  background-color: var(--blue-main);
  width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
  div.header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    img {
      width: 100px;
    }
    @media (min-width: 1080px) {
      width: 70rem !important;
    }
  }
  div.options {
    font-size: 1.5rem;
    width: 30rem;
    ul {
      display: flex;
      justify-content: space-evenly;
      list-style-type: none;
      li a {
        text-decoration: none;
        color: white;
        transition: text-decoration 0.5s;
        :hover {
          text-decoration: underline;
        }
      }
    }
  }
  @media (min-width: 900px) {
    padding: 0.75rem 3rem;
  }
  @media (max-width: 768px) {
    div.options {
      display: none;
    }
  }
`;
