import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 9rem;
  div.videoWrapper {
    padding: 50% 0 0 0;
    position: relative;
    justify-content: center;
    iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }
  @media (min-width: 900px) {
    div.videoWrapper {
      padding: 30% 0 0 0;
    }
  }
`;
