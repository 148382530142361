import { Container } from './styles';

interface InfoCardProps {
  iconName: string;
  iconSource: string;
  cardTitle: string;
  description: string;
}

export function InfoCard(props: InfoCardProps) {
  return (
    <Container>
      <img src={props.iconSource} alt={props.iconName} />
      <h2>{props.cardTitle}</h2>
      <p>{props.description}</p>
    </Container>
  );
}
