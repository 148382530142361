import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  text-align: center;
  margin: 3rem 0;
  a {
    padding: 1rem 2rem;
    text-decoration: none;
    font-weight: 500;
    width: 12rem;
    color: white;
    background-color: var(--orange-main);
    border-radius: 5px;
    cursor: pointer;
    :hover {
      background-color: var(--orange-dark);
    }
  }
`;
