import { Container } from './styles';

export function Header() {
  return (
    <Container>
      <div className="header">
        <img
          src="https://s3-sa-east-1.amazonaws.com/uploads-trilhante-sp/posts/unorte/unorp%201.png?_t=1649968176"
          alt="Logo Unorte"
        />
        <div className="options">
          <ul>
            <li>
              <a href="#intro">Intro</a>
            </li>
            <li>
              <a href="#about">Curso</a>
            </li>
            <li>
              <a href="#prof">Professora</a>
            </li>
            <li>
              <a href="#prices">Preços</a>
            </li>
          </ul>
        </div>
      </div>
    </Container>
  );
}
