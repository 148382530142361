import styled from 'styled-components';
import Swiper from 'swiper';

export const Container = styled.div`
  width: 1000px;
  max-width: 100vw;
  margin: 0 auto;
  text-align: center;
`;

export const Slide = new Swiper('.slides', {
  slidesPerView: 1,
  spaceBetween: 10,
  breakpoints: {
    650: {
      slidesPerView: 2,
      spaceBetween: 5
    },
    900: {
      slidesPerView: 3,
      spaceBetween: 10
    }
  },
  centeredSlides: true,
  rewind: true
});
