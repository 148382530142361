import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-evenly;
  padding: 1rem 2rem;
  background-color: var(--blue-main);
  height: 15rem;
  div.contact {
    color: white;
    h3 {
      color: white;
      margin-bottom: 1rem;
    }
    img {
      max-width: 20px;
      margin-right: 0.25rem;
    }
    a {
      color: white;
      text-decoration: none;
      display: flex;
      align-items: center;
    }
    ul {
      list-style-type: none;
    }
    li {
      display: flex;
      align-items: center;
      padding: 0.5rem 0;
    }
  }
  div.partners {
    h3 {
      color: white;
      margin-bottom: 1rem;
    }
    img {
      width: 150px;
    }
  }
  @media (min-width: 900px) {
    padding: 1rem 5rem;
    align-items: center;
    div.contact {
      img {
        max-width: 30px;
        margin-right: 0.5rem;
      }
    }
    div.partners {
      img {
        width: 300px;
      }
    }
  }
`;
