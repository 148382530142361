import React from 'react';
import { Header } from './components/Header';
import { CardSwiper } from './components/CardSwiper';
import { VideoPanel } from './components/VideoPanel';
import { GlobalStyle } from './styles/global';
import { ProfessorPanel } from './components/ProfessorPanel';
import { PricesPanel } from './components/PricesPanel';
import { Footer } from './components/Footer';

function App() {
  return (
    <>
      <GlobalStyle />
      <Header />
      <VideoPanel />
      <CardSwiper />
      <ProfessorPanel />
      <PricesPanel />
      <Footer />
    </>
  );
}

export default App;
